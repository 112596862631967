.CommentCarousel-display {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
  padding: 10px 0;
  position: relative;
}

.CommentCarousel-display::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.CommentCarousel-display {
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

.CommentCarousel-card {
  flex: 0 0 auto;
  width: calc(33.33% - 20px); /* Default: Four cards per row with some margin */
  margin: 0 10px;
}

@media (max-width: 1200px) {
  .CommentCarousel-card {
    width: calc(33.33% - 20px); /* Three cards per row with some margin */
  }
}

@media (max-width: 900px) {
  .CommentCarousel-card {
    width: calc(50% - 20px); /* Two cards per row with some margin */
  }
}

@media (max-width: 600px) {
  .CommentCarousel-card {
    width: calc(100% - 20px); /* One card per row with some margin */
  }
}

.CommentCarousel-card .MuiCardContent-root {
  height: 250px; /* Fixed height for the card content */
  overflow: hidden; /* Hide overflow text */
  position: relative;
  padding: 8px; /* Reduced padding */
}

.CommentCarousel-card .MuiCardContent-root .content {
  display: -webkit-box;
  -webkit-line-clamp: 10; /* Increased number of lines to show */
  line-clamp: 10; /* Standard property for line clamping */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.CommentCarousel-card .MuiCardContent-root .ellipsis {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  padding-left: 5px;
}

.comment-name {
  font-size: 26px; /* Example size for the name */
  color: #ff3300; /* Example color for the name */
}

.comment-title {
  font-size: 26px; /* Example size for the title */
  font-weight: bold;
  color: #000; /* Example color for the title */
}

.comment-review {
  font-size: 26px; /* Example size for the review */
  color: green; /* Example color for the review */
}