.cartitems{
    width: 80%;
    background: white;
    margin: auto;
    margin-top: 10px;
    border:2px solid #C5001A;
    text-align: center;
    padding:10px;
}
.cartitems-format-main{
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 0.5fr ;
    align-items: center;
    padding:5px 0px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
}
.carticon-product-icon{
    height: 62px;
}

.cartitems-main-button {
    width: 50%;
    height: 20%;
    color: white !important;;
    background: #C5001A !important; 
    border: none;
    font-size: 10px;
    
}


.cartitems-total-item{
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
}

.cartitems-total{
    margin-top: 20px;
}


.cartitems-down{

    width:60%;
}
@media(max-width:800px){
    .cartitems{
        width: 90%;
    }
    .carticon-product-icon{
        height: 46px;
    }
    .cartitems-format-main{
        font-size: 12px;
    }
    .cartitems-down{

        width:98%;
    }
}