.offers{
    width:80%;
    max-width: 1300px;
    height: 30vh;
    min-height: 300px;
    display: flex;
    margin:auto;
    padding: 0px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    background: linear-gradient(180deg,#cbcfd3 0%,#fff 60%) ;

    /*background: white;*/
    box-shadow: 0 5px 10px -5px #11385a;
}

.offers-left{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background-color: red;*/
    width: 100% !important;
}

.offers-left h1{
    font-size: 30px;
    font-weight: 600;
    color:#0b2946;
    margin-bottom: 20px;
    text-align: center;
}

.offers-left p{
    color: #171717;
    font-size: 22px;
    font-weight: 600;
}

.offers-left button{
    width: 282px;
    height:70px;
    border-radius: 35px;
    background: #C5001A;
    border:none;
    color: white;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;
}

.offers-right{
    flex:1;
    display:flex;
    flex-direction: column;
    font-size: 20px;
    justify-content: center;
}

.offers-right img{
    width: 300px;
}

.offers-right-second{
    margin-top: 20px;
    font-size: 15px;
}

.offers-right h2{
    font-size: 22px;
}

.offers-feat{
    margin-top: 10px;
    font-size: 16px;
}


@media(max-width:1200px){

    .offers{
        width:90%;
        max-width: 700px;
        height: 210px;
        min-height: 180px;
        margin-bottom: 5px;
        margin-top: 10px;
        text-align: center;

 }
 .offers-left h1{
    font-size: 20px;
    text-align: center;
    
}

.offers-left{
    display: none;
}


.offers-right{

    font-size: 18px;

}

.offers-right h2{
    font-size: 20px;
}


}


@media(max-width:800px){

    .offers-left h1{
        font-size: 18px;
        text-align: center;
        
    }

    .offers-left button{
        font-size: 18px !important;
    }
    
    
    .offers-right{

        font-size: 14px !important;

    }
    
    .offers-right h2{
        font-size: 18px;
    }
    

    .offers-right-second{
        display:none;
        font-size: 12px;
    }

    .offers-right p{
        font-size: 12px;
    }
    

    
}


/*
@media(max-width:900px){
    .offers{
        width:90%;
        max-width: 900px;
        
 }
    

 .offers-left button{
    width: 200px;
    height:60px;
}

}

@media(max-width:500px){
    .offers{
        width:95%;
        max-width: 1300px;
        height: 40vh;
        margin-bottom: 5px;
        margin-top: 10px;


 }
 .offers-left h1{
    font-size: 24px;
    font-weight: 600;
    color:#0b2946;
    margin-bottom: 20px;
}


 .offers-left button{
    width: 150px;
    height:60px;
}


}
*/